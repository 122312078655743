import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AddExternalBeneficiaryComponent } from './add-external-beneficiary/add-external-beneficiary.component';
import { CercaUtenzeModalContentComponent } from './find-internal-beneficiary/find-internal-beneficiary.component';
import { BeneficiaryModel } from './model/beneficiary.model';
import { BeneficiaryAssembler } from './model/beneficiary.assembler';
import { InternalBeneficiaryModel } from './find-internal-beneficiary/model/internal.beneficiary.model';
import { ExternalBeneficiaryModel } from './add-external-beneficiary/model/external.applicant.model';
import { AuthenticationService } from 'src/app/core/service/auth/authentication.service';
import { UsersService } from 'src/app/user/service/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-request-beneficiary-data',
  templateUrl: './beneficiary-data.html'
})
export class BeneficiaryDataComponent implements OnInit {

  @Input() beneficiaryErd: any;
  beneficiary: BeneficiaryModel;
  employeeId: string;
  @Input() trasferte: boolean = false;
  @Input() isBeneficiaryExternalVisible: boolean = true;
  @Output() beneficiaryEm: EventEmitter<BeneficiaryModel> = new EventEmitter();
  loadData: boolean = false;

  constructor(
    private modalService: NgbModal,
    private beneficiaryAssembler: BeneficiaryAssembler,
    private authenticationService: AuthenticationService,
    private usersService: UsersService) { }

  ngOnInit() {

    this.employeeId = this.authenticationService.tokenJson.sub;
    if (!this.beneficiaryErd) {
      this.usersService.find(this.employeeId).subscribe(
        (data) => {
          this.beneficiary = new InternalBeneficiaryModel();
          (<InternalBeneficiaryModel>this.beneficiary).user_id = data.body.id;
          this.beneficiary.firstName = data.body.first_name;
          this.beneficiary.lastName = data.body.last_name;
          this.beneficiary.email = data.body.email;
          this.beneficiary.matricola = data.body.matricola;
          this.beneficiary.type = "internal";
          (<InternalBeneficiaryModel>this.beneficiary).cdc_code = data.body.cdc;
          this.beneficiaryEm.emit(this.beneficiary);
          this.loadData = true;
        },
        (error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
          } else {
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`
            );
          }
        }
      );
    } else {
        let support = Object.assign({}, this.beneficiaryErd);
        this.beneficiary = new InternalBeneficiaryModel();
    (<InternalBeneficiaryModel>this.beneficiary).user_id = support.id;
        this.beneficiary.firstName = support.first_name;
        this.beneficiary.lastName = support.last_name;
        this.beneficiary.email = support.email;
        this.beneficiary.matricola = support.matricola;
        this.beneficiary.type = 'internal';
        (<InternalBeneficiaryModel>this.beneficiary).cdc_code = support.cdc;
        this.beneficiaryEm.emit(this.beneficiary);
        this.loadData = true;
    }
  }

  isExternalBeneficiary() {
    return this.beneficiary instanceof ExternalBeneficiaryModel;
  }

  openModalInternalBeneficiarySearch() {
    const modalRef = this.modalService.open(CercaUtenzeModalContentComponent, { size: 'lg' });
    modalRef.componentInstance.isBeneficiary = true;
    modalRef.result.then((result) => {
      if (result) {
        this.beneficiary = this.beneficiaryAssembler.toModel(result);
        this.beneficiary.type = "internal";
        this.beneficiaryEm.emit(this.beneficiary);
      }
    }, (reason) => {
      return `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalNewExternalBeneficiary() {
    const modalRef = this.modalService.open(AddExternalBeneficiaryComponent, { size: 'lg' });
    if(window.location.pathname.includes("trasferte")) modalRef.componentInstance.trasferte = true;
    else modalRef.componentInstance.trasferte = false;
    modalRef.result.then((result) => {
      if (result) {
        this.beneficiary = result;
        this.beneficiary.type = "external";
        this.beneficiaryEm.emit(this.beneficiary);
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  };
}

