import { AreaModel } from "../../area.model";


export class AreaEnergyModel extends AreaModel  {

    public profile_enabled: any[] = []; 
    // public cdc_associated: any[] = [];
    public cdc_associated = [];
    public enable_disabled : any;
    public hold: boolean;
    public notes: string; 


    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
