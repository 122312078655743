import { Serializer } from 'src/app/core/common/http/serializer';
import { RolesModel } from '../model/roles.model';
import { RolesResource } from './roles.resource';

export class RolesSerializer implements Serializer {

  fromJson(json: any): RolesModel {
    
    const model = new RolesModel();
    model.id = json.id;
    model.description = json.description;

    return model;
  }

  toJson(model: RolesModel): any  {
    
    
    const rolesResource = new RolesResource();
    const arrayOfRoles = model.id;
    const arrayOfDescription = model.description;

    rolesResource.id = arrayOfRoles;
    rolesResource.description = arrayOfDescription;


 

    return rolesResource;
  }

}
