import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaProlabQResource, } from './area-prolabq.resource';
import { AreaProlabQModel } from '../model/area-prolabq.model';



export class AreaProlabQSerializer implements Serializer { 


  fromJson(json: any): AreaProlabQResource { 
  
    const resource = new AreaProlabQResource();
    
    resource.notes = json.notes;
    resource.equivalent_profile_user = json.equivalent_profile_user;
    resource.type_profile = json.type_profile.value;
    return resource;
  }

  toJson(model: AreaProlabQModel): any  {

    const resource = new AreaProlabQResource();
    
    resource.notes = model.notes;
    resource.equivalent_profile_user = model.equivalent_profile_user;
    resource.type_profile = model.type_profile[0];
    return resource;
  }
}
