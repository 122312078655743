import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionType } from 'src/app/approvazioni/approvazioni-action/resource/action.resource';
import { ProcessRestService } from 'src/app/approvazioni/service/process-rest.service';

@Component({
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Confirm</h4>
    </div>
    <div class="modal-body">
      <p>{{actionBody}}</p>
    <div></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default waves-effect"  (click)="confirm()" >Conferma</button>
        <button type="button" class="btn btn-danger waves-effect waves-light" (click)="cancel()" >Cancel</button>
      </div>
  `
})
export class RichiesteActionComponent {

  @Input() public actionBody: string;

  constructor(
  private modalService: NgbModal,
  public activeModal: NgbActiveModal) {}

  confirm() {
    // Restituisco la confirma
    this.activeModal.close({confirm: true});
  }

  cancel() {
    this.activeModal.close('Close click');
  }

}

@Component({
  template: `
  <div class="modal-header">
  <h4 class="modal-title">{{actionModalTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"s>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
          <label for="inputEmail3" class="control-label col-form-label">Commento</label>
          <textarea  [maxLength]="1000" class="form-control" id="comment" [(ngModel)]="comment" [ngModelOptions]="{standalone:true}" rows="3" placeholder="Inserire un commento"></textarea>
    </div>
    <div class="form-group mt-1">
          <label class="nopadding clrlbl"> Caratteri inseriti: {{comment.length}}/1000</label>
    </div>
  </form>
<div></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default waves-effect"  (click)="close()" >annulla</button>
    <button type="button" class="btn btn-danger waves-effect waves-light" (click)="action($event)" >conferma</button>
  </div>`

})
export class ActionRichiesteModalComponent implements OnInit {
  comment: string;

  @Input() public actionType: ActionType;
  @Input() public taskId: string;

  actionModalTitle: string;
  constructor(
    private _modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private _processService: ProcessRestService) {}

  ngOnInit() {
    let actionTitle;
    switch (this.actionType) {
      case ActionType.APPROVE: {
        actionTitle = 'Approvazione';
        break;
      }
      case ActionType.DENY: {
        actionTitle = 'Rifiuto';
        break;
      }
      case ActionType.CANCEL: {
        actionTitle = 'Annulla';
        break;
      }
   }
    this.actionModalTitle = actionTitle;
    this.comment = '';
  }
  action(event:any) {
     // Apro modale di conferma in caso di rifiuto
     event.preventDefault();
     this.approvalAction(this.actionType, this.comment);
  }
  close() {
    this.activeModal.dismiss('Cross click');
  }

  approvalAction(actionType: ActionType, comment ?: string) {
   // Restituisco la response
    this.activeModal.close({actionType: actionType, comment: comment});
  }
}












