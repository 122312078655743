import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaP2WebModel } from '../model/area-p2-web.model';
import { AreaP2WebResource, UserOpAfTypeResource } from './area-p2-web.resource';


export class AreaP2WebSerializer implements Serializer {
  fromJson(json: any): AreaP2WebResource {

    const resource = new AreaP2WebResource();

    resource.type_module = new UserOpAfTypeResource();
    resource.type_module.id = json.type_module.id;
    resource.type_module.description = json.type_module.description;    
    resource.cdc = json.cdc;
    resource.notes = json.notes;

    return resource;
  }

  toJson(model: AreaP2WebModel): any  {

    const resource = new AreaP2WebResource();

    resource.type_module = new UserOpAfTypeResource();
    resource.type_module.id = model.type_module[0].id;
    resource.type_module.description = model.type_module[0].description;
    resource.cdc = model.cdc;
    resource.notes = model.notes;

    return resource;


  }
}
