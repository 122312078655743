import { AreaModel } from "../../area.model";


export class AreaJaggaerModel extends AreaModel  {

    public role: any; 
    public notes: string; 
  

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
