import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaSupervisionResource, SystemResource } from './area-supervision.resource';
import { AreaSupervisionModel } from '../model/area-supervision.model';


export class AreaSupervisionSerializer implements Serializer {

  fromJson(json: any): AreaSupervisionResource {

    const resource = new AreaSupervisionResource();

    resource.system = new SystemResource();
    resource.system.id = json.system.id;
    resource.system.description = json.system.description;
    resource.notes = json.notes;



    return resource;
  }

  toJson(model: AreaSupervisionModel): any  {
    

    const resource = new AreaSupervisionResource();
  
    resource.notes = model.notes;
    resource.system = new SystemResource();
    resource.system.id = model.system[0].id;
    resource.system.description = model.system[0].description;



    return resource;
  }
}
