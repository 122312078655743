import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	constructor(public loaderService: LoaderService) {}

	private requests: HttpRequest<any>[] = [];

	removeRequest(req: HttpRequest<any>) {
		const i = this.requests.indexOf(req);
		if (i >= 0) {
			this.requests.splice(i, 1);
		}
		this.loaderService.isLoading.next(this.requests.length > 0);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.requests.push(req);
		this.loaderService.isLoading.next(true);
		return new Observable((observer) => {
			const subscription = next.handle(req).subscribe(
				(event) => {
					if (event instanceof HttpResponse) {
						if(	event.status == 200 && 
							event.body == null && 
							event.url == null){
								return () => {
									this.removeRequest(req);
									subscription.unsubscribe();
								};
						}else{
							this.removeRequest(req);
							observer.next(event);
						}
					}
				},
				(err) => {
					this.removeRequest(req);
					observer.error(err);
				},
				() => {
					this.removeRequest(req);
					observer.complete();
				}
			);
			// remove request from queue when cancelled
			return () => {
				this.removeRequest(req);
				subscription.unsubscribe();
			};
		});
	}
}
