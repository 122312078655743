import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ROUTES } from './menu-items';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/service/auth/authentication.service';
import { UserService } from 'src/app/core/service/user/user-service.service';
import { UsersService } from 'src/app/user/service/user.service';

declare let $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  toggleSide(liName) {
    if (!liName.currentTarget.classList.contains('has-arrow')) {
      this.toggleSidebar.emit();
    }
  }

  showMenu = '';
  showSubMenu = '';
  ruoloUser: string;
  ruoloApprover: boolean = true;
  roleSuperAdmin: boolean = false;
  roleAppAdmin: boolean = false;
  roleEngAdmin: boolean = false;
  ruoloAdmin: boolean = false;
  roleAdminBusinessTrip: boolean = false;
  isGestione: boolean = false;
  isViewerApplications: boolean = false;
  isViewerManagment: boolean = false;
  noShowSociety: boolean = false;

  public sidebarnavItems: any[];

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private usersService: UsersService
  ) {
    this.usersService.currentMessage.subscribe(message => this.ngOnInit());
  }

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);

    this.ruoloUser = this.authenticationService.ruoloUser;
    if (this.ruoloUser.includes('ROLE_APPROVER')) {
      this.ruoloApprover = false;
    }
    if (this.ruoloUser.includes('ROLE_ADMIN')) {
      this.ruoloAdmin = true;
    }
    if (this.ruoloUser.includes('ROLE_SUPER_ADMIN')) {
      this.roleSuperAdmin = true;
    }
    if (this.ruoloUser.includes('ROLE_ADMIN_APPLICATIONS')) {
      this.roleAppAdmin = true;
    }
    if (this.ruoloUser.includes('ROLE_ADMIN_ENGAGEMENT')) {
      this.roleEngAdmin = true;
    }
    if (this.ruoloUser.includes('ROLE_ADMIN_BUSINESS_TRIP')) {
      this.roleAdminBusinessTrip = true;
    }
    if (this.ruoloUser.includes('ROLE_HRBP') || this.ruoloUser.includes('ROLE_DIRECTOR') || this.ruoloUser.includes('ROLE_PAYROLL')) {
      this.isGestione = true;
    }
    if (this.ruoloUser.includes('VIEWER_APPLICATIONS')) {
      this.isViewerApplications = true;
    }
    if (this.ruoloUser.includes('VIEWER_ENGAGEMENT')) {
      this.isViewerManagment = true;
    }
    if (localStorage.getItem("society") !== null && (localStorage.getItem("society") != "undefined")) {
      var society = JSON.parse(localStorage.getItem("society"));
      if (society != null) {
        if (society != '1' && society != '36' && society != '45') {
          this.noShowSociety = true;
        }
      }
    }
  }

}
