import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user/user-service.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
}
)
export class AuthenticationService  {

  loggedIn: boolean;
  tokenJson: any;
  token: string;
  ruoloUser: string;

  constructor(
    private router: Router,
    private userService: UserService,
    public jwtHelper: JwtHelperService,
    private http: HttpClient
  ) { }

  public setToken(token: string) {
    localStorage.setItem('token', token);
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    this.token = this.getToken();
    if(this.token !== null){
      this.tokenJson = this.jwtHelper.decodeToken(this.token);
      this.ruoloUser = this.tokenJson.role;
      localStorage.setItem('role', this.ruoloUser);
      localStorage.setItem('submitter', this.tokenJson.sub);
    }
    return !this.jwtHelper.isTokenExpired(this.token);
  }
  
  infoLocalExist(){
    let res = true;
    if(localStorage.length ==0 || localStorage.getItem('role')==null || localStorage.getItem('token')==null ){
      res = false;
    }
    return res
  }

  signOut(): void {
    localStorage.clear();
  }

  public isSuperAdmin(): boolean {
    return this.ruoloUser.includes('ROLE_SUPER_ADMIN');
  }
}
