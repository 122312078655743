import { Resource } from 'src/app/core/common/http/resource.model';

import { SubmitterResource } from 'src/app/richieste/resource/submitter.resource';



export class AssunzioniInfoResource extends Resource  {
  raas: AssunzioniRichiestaResource;
  process: any;
  census_candidato: CensimentoCandidatoResource;
  census_negoziato: CensimentoNegoziatoResource;
  trasparent_selection: any;
  census: any;
  constructor() {
    super();
  }
}

export class AssunzioniRichiestaResource extends Resource {
  id: number;
  process_id: string;
  type: string;
  created_at: string;
  modify_at: string;
  beneficiary: any;
  hiring : HiringResource;
  submitter_id: number;
  submitter: SubmitterResource;
  general_information: HiringResource;
  description_profile: HiringResource;
  offer: HiringResource;
  equipment: HiringResource;
  submitter_superior: string;
  status: string;
  ticket_id : string;
  requestReason: string;
  title: string;
  contractType: string;
  cdc: string;
  managementId: number;
  engagement_id: number;
  census_id: number;
  equipment_full: HiringResource;

  constructor() {
    super();
  }
}

export class CensimentoCandidatoResource extends Resource {
  candidate_registry: CensimentoCandidatoResource;
  last_name: string;
  first_name: string;
  fiscal_code: string;
  street: string;
  house_number: number;
  cap: number;
  city: string;
  nationality: string

  constructor() {
    super();
  }
}


export class CensimentoNegoziatoResource extends Resource {
  negotiated: CensimentoNegoziatoResource;
  starting_date_activity: Date;
  trial_period: number;
  ral_agreed: number;
  variable: any;
  documents: any;
  ticket: string;
  special_conditions: string;
  specifications: string;
  effective_date_start_activity: Date;
  number_monthly_payments: number;
  rlm: number;

  constructor() {
    super();
  }
}


export class HiringResource extends Resource  {

  public id;
  public request_reason: any[];
  public commessa: AssignmentResource;
  public summary_sheet: any;
  public employee_breakoff: any;
  public employee_date_breakoff: Date;
  public other: string;
  public expect_budget: any[];
  public job: string;
  public refer_to: EmployeeResource;
  public head_office: string;
  public hypothesized_qualification: any;
  public worker: any[];
  public intermediate: any[];
  public officeWorker: any[];
  public qualification_level: any;
  public hypothesized_contract: any;
  public subordinateContract: any[];
  public apprenticeship: string;
  public administeredContract: number;
  public StageContract: any[];
  public StageContractDuration: string;
  public part_time: any;
  public partTimeHours: string;
  public shift_worker: any;
  public cost_type: any[];
  public cdc: string;
  public ral: any;
  public candidate_offer: any;
  public candidate: any;
  public link_cv: string;
  public assumed_start_date: string;
  public car: any;
  public autoBenefitCar: any;
  public carList: any;
  public telepass: any;
  public smartphone: any;
  public description_profile: any;
  public offer: any;
  public equipment: any;
  constructor() {
      super();
    }
}

export class AssignmentResource extends Resource  {
  /*public number: string;
  public subject: string;*/
  public numberOrSubject: string;
  constructor() {
      super();
  }
}


export class EmployeeResource extends Resource  {
  public role: string;
  public displayName: string;
  constructor() {
      super();
  }
}


export class ApplicantOfferDataResource extends Resource  {
  public firstName: string;
  public lastName: string;
  constructor() {
      super();
  }
}



