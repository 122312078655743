
import { Resource } from 'src/app/core/common/http/resource.model';
import { AreaResource, AreaType } from '../../area.resource';

export class AreaProlabQResource extends AreaResource {
   public static readonly TYPE =  AreaType.prolabq;

   equivalent_profile_user: string;
   notes: string;
   type_profile : any;
   constructor() {
    super(AreaProlabQResource.TYPE);
   }
}


export class UserOfAProlabQTypeResource extends Resource {
   id: string;
   value: string;
  
   constructor() {
     super();
 
   }
 }
 


