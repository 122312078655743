import { Resource } from 'src/app/core/common/http/resource.model';

export class EcmListResource extends Resource {

  id: string;
  description: string;
  value: string;
 

  constructor() {
    super();
  }

}