import { AreaModel } from "../../../area.model";

export class AreaFlooraModel extends AreaModel  {

    public type_user: any[] = []; //type_user
    public type: string; //office
    public type_portal: any[] = [];
    public profile: string;  //type_wallet
    public notes: string;  //notes

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}