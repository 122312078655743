import { Resource } from 'src/app/core/common/http/resource.model';
import { AreaResource, AreaType } from '../../area.resource';

export class AreaEnergyResource extends AreaResource {
   public static readonly TYPE =  AreaType.energy;

   profile_enabled: AssignmentResource;
   cdc_associated = [];
   hold: boolean;
   notes: string;
   enabled_disabled : boolean;

   constructor() {
    super(AreaEnergyResource.TYPE);
   }
}

 export class AssignmentResource extends Resource { 
  profile: ProfileResource;
  enabled_disabled: boolean;
  enable_disabled: boolean;

  constructor() {
   super();
 }
} 

export class ProfileResource extends Resource { 
  id: number;
  description: string;


  constructor() {
   super();
 }
} 

 export class CdcResource extends Resource { 
   value: string;
   enable_disabled: boolean;
   
   constructor() {
    super();
  }
 }
