import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaOracleFieldServiceCloudResource, UserOfscTypeResource, SkillOfscType100Resource, SkillOfscType50Resource } from './area-ofsc.resource';
import { AreaOracleServiceCloudModel } from '../model/area-osfc-.model';


export class AreaOracleFieldServiceCloudSerializer implements Serializer {
  fromJson(json: any): AreaOracleFieldServiceCloudResource {

    const resource = new AreaOracleFieldServiceCloudResource();
    resource.type_user =  new UserOfscTypeResource();
    resource.type_user.id = json.type_user.id;
    resource.type_user.type = json.type_user.type;
    if (json.skill_of_type_100 !== null){
      resource.skill_of_type_100 = new SkillOfscType100Resource();
      resource.skill_of_type_100.id = json.skill_of_type_100.id;
      resource.skill_of_type_100.type = json.skill_of_type_100.type;
    }
  
    if (json.skill_of_type_50 !== null){
      resource.skill_of_type_50 = new SkillOfscType50Resource();
      resource.skill_of_type_50.id = json.skill_of_type_50.id;
      resource.skill_of_type_50.type = json.skill_of_type_50.type;
    }
    resource.membership_group = json.membership_group ? json.membership_group : '';
    resource.working_area = json.working_area ? json.working_area : '';
    resource.notes = json.notes;

    return resource;
  }

  toJson(model: AreaOracleServiceCloudModel ): any  {
    const resource = new AreaOracleFieldServiceCloudResource();
    resource.type_user = new UserOfscTypeResource();
    resource.type_user.id = model.typeUser[0].id;

    if(model.skillType100.length === 0){
      resource.skill_of_type_100 = new SkillOfscType100Resource();
      resource.skill_of_type_100 = null;
    } else {
      resource.skill_of_type_100 = new SkillOfscType100Resource();
      resource.skill_of_type_100.id = model.skillType100[0].id;
    }
    
    if(model.skillType50.length === 0){
      resource.skill_of_type_50 = new SkillOfscType50Resource();
      resource.skill_of_type_50 = null;
    } else {
      resource.skill_of_type_50 = new SkillOfscType50Resource();
      resource.skill_of_type_50.id = model.skillType50[0].id;
    }


    resource.membership_group = model.membershipGroup;
    resource.working_area = model.workingArea;
    resource.notes = model.notes;

    return resource;

  }
}
