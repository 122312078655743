import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaRequestpecModel } from '../model/area-requestpec.model';
import { AreaRequestpecResource } from './area-requestpec.resource';


export class AreaRequestpecSerializer implements Serializer { 

  fromJson(json: any): AreaRequestpecResource { 

    const resource = new AreaRequestpecResource();
    resource.email = json.email;
    resource.notes = json.notes;

    return resource;
  }

  toJson(model: AreaRequestpecModel): any  {

    const resource = new AreaRequestpecResource();
    resource.email = model.email;
    resource.notes = model.notes;
    
    return resource;
  }
}
