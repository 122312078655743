import { Serializer } from 'src/app/core/common/http/serializer';
import { EcmGroupModel } from '../model/ecm-group.model';
import { EcmListResource } from './ecmListResource.resource';

export class EcmGroupSerializer implements Serializer {

  fromJson(json: any): EcmGroupModel {
    const model = new EcmGroupModel();
    model.id = json.id;
    model.description = json.description;
    model.value = json.value;
    return model;
  }

  toJson(model: EcmGroupModel): any  {
    const ecmListResource = new EcmListResource();
    const array = model.id;
    const arr2 = model.description;
    const arr3 = model.value;
    ecmListResource.id = array;
    ecmListResource.description = arr2;
    ecmListResource.value = arr3;
    return ecmListResource;
  }

}