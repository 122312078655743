import { Serializer } from 'src/app/core/common/http/serializer';
import { UserResource } from './user.resource';


export class UserSerializer implements Serializer {
  fromJson(json: any): UserResource {

    const resource = new UserResource();
    if(json.user !== undefined){
      resource.id = json.user.id;
      resource.last_name = json.user.last_name;
      resource.first_name =  json.user.first_name;
      resource.email =  json.user.email;
      resource.image_url =  json.user.image_url;
      resource.display_name = json.user.display_name;
      resource.cdc = json.user.cdc;
      resource.matricola = json.user.matricola;
      resource.society = json.user.society;
      resource.typology = json.typology
    } else {
      resource.id = json.id;
      resource.last_name = json.last_name;
      resource.first_name =  json.first_name;
      resource.email =  json.email;
      resource.image_url =  json.image_url;
      resource.display_name = json.display_name;
      resource.cdc = json.cdc;
      resource.matricola = json.matricola;
      resource.typology = json.typology
    }
    return resource;
  }

  toJson(resource: UserResource): any  {
    return {};
  }
}
