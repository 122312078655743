import { Resource } from 'src/app/core/common/http/resource.model';

export class AreaResource extends Resource {

  type: AreaType;
  notes: string;

  constructor(type: AreaType) {
    super();
    this.type = type;
  }

}

export enum AreaType {
  summary = 'summary',
  ofsc = 'oracle_field_service_cloud',
  salesforce = 'salesforce',
  tempozero = 'tempozero',
  fusion = 'oracle_fusion',
  piteco = 'piteco',
  floora = 'asset_management',
  p2Web = 'p2web',
  ecm = 'ecm',
  energy = 'energy',
  etime = 'etime',
  timesheet = 'timesheet_dto',
  esight = 'esight',
  mappature = 'mappature',
  supervision_systems = 'supervision_systems',
  account_google = 'account_google',
  account_active_directory = 'account_active_directory',
  igateway = 'igateway',
  prolabq = 'prolabq',
  jaggaer = 'jaggaer',
  bolla_lavoro = 'bolla_lavoro',
  request_pec = 'request_pec'
}
