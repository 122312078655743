import { Model } from 'src/app/core/common/model/model';

export class BeneficiaryModel extends Model {

  public firstName: string ;
  public lastName: string;
  public email: string;
  public matricola? : string;
  public type? : string;


constructor() {
    super();
  }

 public toString() {
  return `BeneficiaryModel (${this.firstName}) (${this.lastName}) (${this.email})`;
  }
}
