import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaEcmResource, } from './area-ecm.resource';
import { AreaEcmModel } from '../model/area-ecm.model';
import { EcmGroupSerializer } from './ecm-group-serializer';
import {  EcmListResource } from './ecmListResource.resource';
import { EcmGroupModel } from '../model/ecm-group.model';



export class AreaEcmSerializer implements Serializer { 

  ecmGroupSerializer: EcmGroupSerializer;

  constructor() {
    this.ecmGroupSerializer = new EcmGroupSerializer();
   }

  fromJson(json: any): AreaEcmResource { 
    
    const resource = new AreaEcmResource();
    resource.notes = json.notes;
  
    if (json.assignment_groups) {
      
      resource.assignment_groups = new Array<EcmListResource>();
      for (const element of json.assignment_groups) {
        const ecmListResource = new EcmListResource();
        ecmListResource.id = element.id;
        ecmListResource.description = element.description;
        ecmListResource.value = element.value;

        resource.assignment_groups.push(ecmListResource);
     }
    } else {
      resource.assignment_groups = [];
    }

    return resource;
  }


  

  toJson(model: AreaEcmModel): any  {
    const resource = new AreaEcmResource();
    if (model) {
      resource.assignment_groups = new Array<EcmListResource>();
      for (const element of model.assignment_groups) {
         resource.assignment_groups.push(this.ecmGroupSerializer.toJson(<EcmGroupModel>element));
      } 
    } 
    
    resource.notes = model.notes;

    return resource;


  }
}
