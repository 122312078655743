import { AreaModel } from '../../area.model';

export class AreaPitecoModel extends AreaModel  {

    public type_user: any[] = [];
    public belonging_office: any[] = [];
    public notes: string;

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
