import { Routes } from '@angular/router';
import { AuthGuardService } from '../app/core/service/auth/auth-guard-service.service';
import { RoleGuardService as RoleGuard } from '../app/core/service/auth/role-guard-service.service';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuardService, RoleGuard],
    children: [
      { path: '', redirectTo: '/home/dashboard', pathMatch: 'full'},
      {
        path: 'home',
        loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'starter',
        loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
      },
      {
        path: 'applicativi',
        loadChildren: () =>import('./richieste/richieste.module').then(m => m.RichiesteModule)               
      },
      {
        path: 'amministrazione/assunzioni',
        loadChildren: () => import('./assunzioni/assunzioni.module').then(m => m.AssunzioniModule)
      },
      {
        path: 'amministrazione/trasferte',
        loadChildren: () => import('./trasferte/trasferte.module').then(m => m.TrasferteModule)
      },
      {
        path: 'amministrazione',
        loadChildren: () => import('./richieste/richieste.module').then(m => m.RichiesteModule)
      },
      {
        path: 'applicativi/approvazioni',
        loadChildren: () => import('./approvazioni/approvazioni.module').then(m => m.ApprovazioniModule)
      },
      {
        path: 'assunzioni',
        loadChildren: () => import('./assunzioni/assunzioni.module').then(m => m.AssunzioniModule)
      },
      {
        path: 'trasferte',
        loadChildren: () => import('./trasferte/trasferte.module').then(m => m.TrasferteModule)
      },
      {
        path: 'data',
        loadChildren: () => import('./data/data.module').then(m => m.DataModule)
      },
      {
        path: 'error',
        loadChildren: () => import('./error/error.module').then(m => m. ErrorModule)
      }
      
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
