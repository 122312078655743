import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { NgbCalendar ,  NgbDateAdapter ,  NgbDateParserFormatter ,  NgbDateStruct  , NgbDatepickerI18n , NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/app/approvazioni/datepicker-adapter';
import { I18n, CustomDatepickerI18nService } from 'src/app/richieste/richieste-list/custom-datepicker-i18n.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ExternalBeneficiaryModel } from './model/external.applicant.model';

@Component({
  selector: 'app-add-external-beneficiary',
  templateUrl: './add-external-beneficiary.html',
  styleUrls:['./add-external-beneficiary.css'],
  providers:[
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddExternalBeneficiaryComponent implements OnInit {


  @Input() trasferte: boolean = false;
  terminated_relationship_at: NgbDateStruct | null;
  externalApplicant: ExternalBeneficiaryModel;
  newBeneficiaryForm: FormGroup;
  minDate: {year: number, month: number, day: number};

  constructor(
    public activeModal: NgbActiveModal,
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter,
    private dateAdapter: NgbDateAdapter<string>
    ) {
      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
    }

    

    ngOnInit() {
      this.externalApplicant = new ExternalBeneficiaryModel();
      this.externalApplicant.is_future_employee = false;
      this.externalApplicant.terminated_relationship_at = null;
      if(this.trasferte) {
        this.newBeneficiaryForm = new FormGroup({
          'inputFirstName': new FormControl(null, [Validators.required]),
          'inputLastName': new FormControl(null, [Validators.required]),
          'role': new FormControl(null, [Validators.required]),
          'inputEmail': new FormControl(null, [Validators.required, Validators.email]),
          'inputCompanyBelonging': new FormControl(null, [Validators.required]),
          'is_future_employee' : new FormControl(null,null)
      }, {updateOn: 'change'});
      } else {
        this.newBeneficiaryForm = new FormGroup({
          'inputFirstName': new FormControl(null, [Validators.required]),
          'inputLastName': new FormControl(null, [Validators.required]),
          'inputEmail': new FormControl(null, [Validators.required, Validators.email]),
          'inputCompanyBelonging': new FormControl(null, [Validators.required]),
          'inputterminated_relationship_at': new FormControl(null, [Validators.required]),
          'is_future_employee' : new FormControl(null,null)
      }, {updateOn: 'change'});
      }
      
  }

  submitFormNewBeneficiary() {
    this.activeModal.close(this.externalApplicant);
  }
  close() {
    this.activeModal.dismiss('Nessun beneficiario esterno selezionato');
  }

  //Reset datepicker
  resetDate():any{
    this.terminated_relationship_at = null;
    return this.terminated_relationship_at ;
  }

  //Update date 
  updateDate($event){
    let res = null;
    if($event!= null && typeof $event != typeof undefined && $event!=""){
      let date = new Date($event);
      let day = date.getDate();
      let month = date.getMonth()+1;
      let year = date.getFullYear();
      let dateString = day+"/"+month+"/"+year;
      let dateObj = this.formatter.parse(dateString);
      res = this.formatter.format(dateObj)
    }
    return res;
  }


}
