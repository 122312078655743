import { Serializer } from 'src/app/core/common/http/serializer';
import { AreajaggaerResource } from './area-jaggaer.resource';
import { AreaJaggaerModel} from '../model/area-jaggaer.model';




export class AreajaggaerSerializer implements Serializer { 


  fromJson(json: any): AreajaggaerResource { 
    const resource = new AreajaggaerResource();
    resource.notes = json.notes;
    resource.role = json.role;
    return resource;
  }

  toJson(model: AreaJaggaerModel): any  {
    const resource = new AreajaggaerResource();
   
    let roleWithoutLinks = model.role[0];
    delete(roleWithoutLinks.links)
  
    resource.notes = model.notes;
    resource.role = roleWithoutLinks;
    
    return resource;
  }
}
