import { AreaModel } from '../../area.model';

export class AreaFusionModel extends AreaModel  {

    public type_user: any[] = [];
    public url: string;
    public buyer: boolean;
    public requester: boolean;
    public approver: boolean;
    public approval_power: number;
    public approval_power_cost_centers: string;
    public notes: string;

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
