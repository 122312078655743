import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaTempozeroResource, UserOfAtzTypeResource } from './area-tempozero.resource';
import { AreaTempozeroModel } from '../model/area-tempozero.model';
import { CommessaSerializer } from './commessa.serializer';
import { CdcSerializer } from './cdc.serializer';
import { CommessaResource } from './commessa.resource';
import { CdcResource } from './cdc.resource';
import { CommessaModel } from '../model/commessa.model';
import { CdcModel } from '../model/cdc.model';


export class AreaTempozeroSerializer implements Serializer {

  commessaSerializer: CommessaSerializer;
  cdcSerializer: CdcSerializer;

  constructor() {
    this.commessaSerializer = new CommessaSerializer();
    this.cdcSerializer = new CdcSerializer();
  }

  fromJson(json: any): AreaTempozeroResource {


    const resource = new AreaTempozeroResource();

    resource.type_user = new UserOfAtzTypeResource();
    resource.type_user.id = json.type_user.id;
    resource.type_user.type = json.type_user.type;
    resource.type_user.description = json.type_user.description;
    resource.personal_approval_power = json.personal_approval_power;
    resource.notes = json.notes;
    resource.cm_riferimento = json.cm_riferimento;

    if (json.commesse) {
      resource.commesse = new Array<CommessaResource>();
      for (const commessa of json.commesse) {
        const commessaResource = new CommessaResource();
        commessaResource.udb_code = commessa.udb_code;
        commessaResource.area_code = commessa.area_code;
        commessaResource.cdc_code = commessa.cdc_code;
        commessaResource.segment_code = commessa.segment_code;
        commessaResource.project_name = commessa.project_name;
        commessaResource.active = commessa.active;
        commessaResource.cdc_type = commessa.cdc_type;

        resource.commesse.push(commessaResource);
      }
    } else {
      resource.commesse = [];

    }

    if (json.cdc) {

      resource.cdc = new Array<CdcResource>();
      for (const cdc of json.cdc) {
        const cdcResource = new CdcResource();
        cdcResource.udb_code = cdc.udb_code;
        cdcResource.area_code = cdc.area_code;
        cdcResource.cdc_code = cdc.cdc_code;

        resource.cdc.push(cdcResource);
      }
    } else {
      resource.cdc = [];

    }



    return resource;
  }

  toJson(model: AreaTempozeroModel): any {
    const resource = new AreaTempozeroResource();
    resource.commesse = new Array<CommessaResource>();

    if (model.centrodicosto != null && model.centrodicosto != "") {
      let commessa = new CommessaResource();
      commessa.active = true;
      commessa.cdc_type = model.centrodicosto;
      resource.commesse.push(commessa);
    } 
    if (model.commesse && model.commesse.length > 0) {
      for (const commessa of model.commesse) {
        resource.commesse.push(this.commessaSerializer.toJson(<CommessaModel>commessa));
      }
    }
    if(resource.commesse.length == 0) {
      resource.commesse = null;
    }

    if (model.cdc) {
      resource.cdc = new Array<CdcResource>();
      for (const cdc of model.cdc) {
        resource.cdc.push(this.cdcSerializer.toJson(<CdcModel>cdc));
      }
    }
    if (model.cm_riferimento) resource.cm_riferimento = model.cm_riferimento;
    resource.personal_approval_power = model.personal_approval_power;
    resource.notes = model.notes;
    resource.type_user = new UserOfAtzTypeResource();
    resource.type_user.id = model.typeUser[0].id; // Conversione typeUser da string a number

    return resource;
  }
}
