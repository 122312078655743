import { AreaModel } from "../../area.model";


export class AreaRequestpecModel extends AreaModel  {

    public email: string; 
    public notes: string;

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
