import { Component, AfterViewInit, OnInit, OnDestroy, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { UsersService } from 'src/app/user/service/user.service';
import { Link } from 'src/app/core/common/http/link.resource';
import { HttpErrorResponse } from '@angular/common/http';
import { PageInfo } from 'src/app/core/common/http/pageinfo.resource';
import { DataTableDirective } from 'angular-datatables';
import { PageQuery } from 'src/app/core/common/http/pageQuery.model';
import { snakeCase } from 'snake-case';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const { isBlank } = require('npm-stringutils');

export class BeneficiarioFilterModel {
  firstName: string;
  lastName: string;
  email: string;
  cdc: string;
}

@Component({
  selector: 'app-find-internal-beneficiary-table',
  templateUrl: './find-internal-beneficiary-table.html',
  styleUrls: ['./find-internal-beneficiary.css']

})
export class FindInternalBeneficiaryTableComponent implements OnInit, OnDestroy, AfterViewInit {
  dtOptions: any = {};

  @Output()
  onselectEvent: EventEmitter<any> = new EventEmitter(); // Emette event quando si selezione/deseleziona riga

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  @Input() public isHRBP = false;
  @Input() public isPayroll = false;
  @Input() public isDelega = false;

  dtTrigger = new Subject<any>();
  dtInstance: DataTables.Api;
  usersList = [];
  nextPageUsers: Link;
  loadingIndicator = true;
  beneficiarioFilter: BeneficiarioFilterModel;
  fgFilterBenificiaryInternal: FormGroup;
  private searchSub$ = new Subject<string>();
  searchTerms: string;
  lastTerms: string;
  isHRBP2: boolean = false;

  constructor(private _usersService: UsersService, private fb: FormBuilder) {
    this.fgFilterBenificiaryInternal = this.fb.group({
      firstName: '',
      lastName: '',
      email: '',
      cdc: '',
    });

    // Abilito il form group
    // onlySelf: will only update this FormControl when true.
    // emitEvent: will cause valueChanges  event to be fired when true.
    this.fgFilterBenificiaryInternal.updateValueAndValidity({ onlySelf: true, emitEvent: true });

    // Quando cambia un valore del form aggiorno la ricerca
    this.fgFilterBenificiaryInternal.valueChanges.subscribe((value) => {
      const searchTerm = value;
      this.searchSub$.next(searchTerm);
      this.searchSub$.pipe(
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe((searchTerm: any) => {
        this.searchTerms = searchTerm;
        if (this.searchTerms != this.lastTerms) {
          if (this.fgFilterBenificiaryInternal.valid) {
            this.search();
          }
        } this.lastTerms = this.searchTerms;
      });
    });

    setTimeout(() => {
      this.loadingIndicator = false;
    }, 1500);
  }

  ngOnInit() {

    if (this.isHRBP) this.isHRBP2 = true;
    const that = this;
    this.dtOptions = {
      select: {
        style: 'single',
        blurable: true,
        info: false
      },
      deferRender: true,
      //  deferLoading: 3000,
      paging: true,
      searching: false,
      pageLength: 10,
      responsive: true,
      columnDefs: [    // Priorità delle colonne da visualizzare su mobile
        { responsivePriority: 0, targets: [0, 1] }

      ],
      pagingType: 'simple_numbers',
      serverSide: true,  // Abilita server-side processing mode. filtering, paging and sorting calculations are all performed by a server.
      processing: true,  // Enable or disable the display of a 'processing' indicator when the table is being processed
      lengthChange: true,
      lengthMenu: [10, 25, 50],
      language: {
        lengthMenu: 'Visualizza _MENU_ righe per pagina',
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw" aria-hidden="true"></i><span class="sr-only">Loading...</span> ',
        zeroRecords: 'Nessun risultato',
        info: 'Mostra pagina _PAGE_ di _PAGES_',
        infoEmpty: 'Nessun record disponibile',
        infoFiltered: '( _MAX_ records totali)',
        oPaginate: {
          "sFirst": 'Inizio',
          "sPrevious": 'Precedente',
          "sNext": 'Successivo',
          "sLast": 'Fine'
        }
      },
      ajax: (dataTablesParameters: any, callback: any) => {

        const pageableSorting = new PageQuery();
        if (dataTablesParameters.length === 10) {
          dataTablesParameters.start = dataTablesParameters.start / 10;
        } else if (dataTablesParameters.length === 25) {
          dataTablesParameters.start = dataTablesParameters.start / 25;

        } else if (dataTablesParameters.length === 50) {
          dataTablesParameters.start = dataTablesParameters.start / 50;
        }

        pageableSorting.page = +dataTablesParameters.start;
        pageableSorting.size = +dataTablesParameters.length;
        pageableSorting.sort = new Array();
        pageableSorting.sort[0] = dataTablesParameters.columns[dataTablesParameters.order[0].column].name + ',' + dataTablesParameters.order[0].dir;

        that.loadListUsersAndRenderingTable(pageableSorting, callback);
      },
      columns: [
        { data: 'last_name', name: 'lastName' },
        { data: 'first_name', name: 'firstName' },
        { data: 'email', name: 'email' },
        { data: 'cdc', name: 'Centro di costo' }],
      drawCallback: function () {
        // Pagination - Add BS4-Class for Horizontal Alignment (in second of 2 columns) & Top Margin
        $('#dtPluginExample_wrapper .col-md-7:eq(0)').addClass('d-flex justify-content-center justify-content-md-end');
        $('#dtPluginExample_paginate').addClass('mt-3 mt-md-2');
        $('#dtPluginExample_paginate ul.pagination').addClass('pagination-sm');
      }
    };

  }

  search() {
    this.setBeneficiarioFilterModel({
      firstName: this.fgFilterBenificiaryInternal.controls['firstName'].value,
      lastName: this.fgFilterBenificiaryInternal.controls['lastName'].value,
      email: this.fgFilterBenificiaryInternal.controls['email'].value,
      cdc: this.fgFilterBenificiaryInternal.controls['cdc'].value,
    });

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  setBeneficiarioFilterModel(filter: BeneficiarioFilterModel) {
    this.beneficiarioFilter = filter;
  }

  onKeyUpFilter(event: any) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    });
  }

  loadListUsersAndRenderingTable(pageableSorting: PageQuery, callback?: any) {

    // convert obj to map datatype in sna.
    const xah_obj_to_map = (obj => {
      const mp = new Map<string, string>();
      Object.keys(obj).forEach(k => {
          if (obj[k] != null && typeof obj[k] === 'string' && isBlank(obj[k])) {
            console.log(obj[k]);
          }else if(obj[k] != null){
            mp.set(snakeCase(k), obj[k]);
          }
      });
      return mp;
    });

    const objFilter = {};
    let mapFilter = xah_obj_to_map(pageableSorting);
    mapFilter.forEach((value, key) => (objFilter[key] = value));
    if (this.isHRBP) objFilter["isHRBP"] = true;
    if (this.isPayroll) objFilter["isPAYROLL"] = true;
    if (this.isDelega) objFilter["role"] = "APPROVER";
    if (this.beneficiarioFilter) {

      const mapFilterApprov = xah_obj_to_map(this.beneficiarioFilter);
      mapFilterApprov.forEach((value, key) => (objFilter[key] = value));
      Object.assign(mapFilter, mapFilterApprov);
    }
    this._usersService.query(objFilter).pipe().subscribe(
      (response) => {
        const content = response.body.content;
        const pageInfo: PageInfo = response.body.page;
        this.usersList = content;

        callback({
          recordsTotal: pageInfo.totalElements,  // Total records, before filtering (i.e. the total number of records in the database)
          recordsFiltered: pageInfo.totalElements, // Total records, after filtering (i.e. the total number of records after filtering has been applied - not just the number of records being returned for this page of data).
          data: content,

          // Optional: If an error occurs during the running of the server-side processing script, you can inform the user of this error by passing back the error message to be displayed using this parameter. Do not include if there is no error.
          // error:
        });
        // Invoco i trigger per renderizzare la table
      },
      (error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`
          );
        }
      }
    );

  }

  ngOnDestroy(): void {
    //  unsubscribe del dtTrigger
    this.dtTrigger.unsubscribe();
  }

  /**
   * Ritorna 'utente selezionato, se esiste, altrimenti undefined
   * @param user
   */
  async getSelectedUser() {
    return await this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      return dtInstance.rows({ selected: true }).data()[0];
    }
    );
  }


  ngAfterViewInit() {
    // Emette un evento quando si seleziona/deseleziona una riga della tabella
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      const self = this;
      dtInstance.on('select', function (e, dt, type, indexes) {
        const row = dt.rows(indexes[0]);

        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).off('click');
        $('td', row).on('click', () => {

        });
        if (type === 'row') {
          let user = dtInstance.rows({ selected: true }).data()[0];
          self.onselectEvent.emit({ rowSelected: true, user: user });
        }
      });

      dtInstance.on('deselect', function (e, dt, type, indexes) {

        if (!self.isHRBP2 && !self.isPayroll) {
          if (type === 'row') {
            self.onselectEvent.emit({ rowSelected: false });

            // do something with the ID of the selected items
          }
        }
      });
    });
  }

}