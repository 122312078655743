export const environment = {
  production: false,
  backend: {
    baseUrl: "https://be-uat.siram.elvoon.it/approva-ws/api/v1",
  },
  oauth2_redirect_url: "https://be-uat.siram.elvoon.it/oauth2/redirect",
  google: { 
    auth_url:
    "https://be-uat.siram.elvoon.it/approva-ws/api/v1/oauth2/authorize/google?redirect_uri=https://approva-uat.siram.elvoon.it/authentication/oauth2/redirect", 
    clientId:
      "1071512568828-qn9kf5fbg68uq3eckatc86ul4m1t2k3q.apps.googleusercontent.com",
      apikeydrive:'AIzaSyBFI8z-odTCBZt0K8k3fwPb8ZAD42GmQiU',
      appId:'1071512568828',
      mapkey:'AIzaSyD5x5lo2OTmK_mCG2Mwom-9viEkSSiR7PQ'
  },
  deleghe: "marco.taranto@veolia.com"
};