import { Component, Input} from '@angular/core';
import { ExternalBeneficiaryModel } from './add-external-beneficiary/model/external.applicant.model';

@Component({
  selector: 'app-beneficiary-data-external',
  templateUrl: './beneficiary-data-external.html'
})
export class BeneficiaryDataExternalComponent  {

  @Input() beneficiary: ExternalBeneficiaryModel;
  @Input() trasferte: boolean = false;
  
}

