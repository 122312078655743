import { Serializer } from 'src/app/core/common/http/serializer';
import { FunctionsSerializer } from './functions.serializer';
import { AreaEsightResource, ProfileResource } from './area-esight.resource';
import { FunctionsResource } from './functions.resource';
import { AreaEsightModel } from '../model/area-esight.model';
import { FunctionModel } from '../model/functions.model';


export class AreaEsightSerializer implements Serializer {

    functionsSerializer: FunctionsSerializer;

   constructor() {
    this.functionsSerializer = new FunctionsSerializer();
   }

  fromJson(json: any): AreaEsightResource {

    const resource = new AreaEsightResource();

    resource.profile = new ProfileResource();
    resource.profile.id = json.profile.id;
    resource.profile.description = json.profile.description;
    resource.commesse = json.commesse;
    resource.notes = json.notes;

    if (json.functions) {
      resource.functions = new Array<FunctionsResource>();
      for (const funzione of json.functions) {
        const functionsResource = new FunctionsResource();
        functionsResource.id = funzione.id;
        functionsResource.description = funzione.description;


    
        resource.functions.push(functionsResource);
     }
    } else {
      resource.functions = [];

    }

    return resource;
  }

  toJson(model: AreaEsightModel): any  {
    

    const resource = new AreaEsightResource();
    if (model) {
      resource.functions = new Array<FunctionsResource>();
      for (const group of model.functions) {
         resource.functions.push(this.functionsSerializer.toJson(<FunctionModel>group));
      } 
    
    } 
    resource.commesse = model.commesse;
    resource.notes = model.notes;
    resource.profile = new ProfileResource();
    resource.profile.id = model.profile[0].id;
    resource.profile.description = model.profile[0].description;




    return resource;
  }
}
