import { Model } from 'src/app/core/common/model/model';

export class EcmGroupModel extends Model {

  id: string;
  description: string;
  value: string;

  constructor() {
    super();
  }

}
