import { Resource } from 'src/app/core/common/http/resource.model';

export class CommentInfoResource extends Resource {

  comments: CommentResource[];

}
export class CommentResource extends Resource {
   id: string;
   user_id: string;
   create_time: string;
   message: string;
   task_id: string;
   type: string;
   process_instance_id: string;
    constructor() {
    super();
  }

}
