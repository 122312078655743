import { Resource } from 'src/app/core/common/http/resource.model';
import { RequestResource } from 'src/app/richieste/resource/request.resource';
import { SubmitterResource } from 'src/app/richieste/resource/submitter.resource';
import { CommentResource } from './comment.model';
import { TaskResource } from './task.resource';


export class ProcessInfoResource extends Resource {

  process: ProcessResource;
  status: any;
  start_time: any;
  
  constructor() {
    super();
  }

}

export class ProcessResource extends Resource {
  type: string;
  id: string;
  status: string;
  startTime: string;
  endTime: string;
  request: RequestResource;
  total: number;
  progress: number;
  comments: CommentResource;
  task: TaskResource[];
  action_submitter:SubmitterResource;
  action_date: string;
  message: string;
  


  constructor(type: string) {
    super();
    this.type = type;
  }

}

export class HistoricProcessResource extends ProcessResource {
  public static readonly TYPE = 'historic';

  constructor() {
    super(HistoricProcessResource.TYPE);

}
}

export class ProcessInstanceResource extends ProcessResource {
  public static readonly TYPE = 'present';

   constructor() {
    super(ProcessInstanceResource.TYPE);

}
}

export class StateResource {
  id: string;
  type: string;
}
