import { AreaModel } from '../../area.model';

export class AreaOracleServiceCloudModel extends AreaModel {

  public typeUser: any[] = [];
  public skillType100: any[] = [];
  public skillType50: any[] = [];
  public membershipGroup: string;
  public workingArea: string;
  public notes: string;

  // Definisco un construttore con valori di default vuoti in sostituzione del costruttore senza parametri
  constructor() {
    super();
   }

}
