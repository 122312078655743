import { Serializer } from 'src/app/core/common/http/serializer';
import { BelongingSerializer } from './belonging_groups.serializer';
import { AreaTimeSheetResource, UserTypologyResource } from './area-timesheet.resource';
import { BelongingGroupResource } from './belonging_groups.resource';
import { AreaTimeModel } from '../model/area-timesheet.model';
import { BGroupModel } from '../model/belonging_groups.model';


export class AreaTimeSheetSerializer implements Serializer {

    belongingSerializer: BelongingSerializer;

   constructor() {
    this.belongingSerializer = new BelongingSerializer();
   }

  fromJson(json: any): AreaTimeSheetResource {

    const resource = new AreaTimeSheetResource();

    resource.typology = new UserTypologyResource();
    resource.typology.id = json.typology.id;
    resource.typology.description = json.typology.description;
    resource.cdc = json.cdc;
    resource.notes = json.notes;

    if (json.belonging_groups) {
      
      resource.belonging_groups = new Array<BelongingGroupResource>();
      for (const group of json.belonging_groups) {
        const belongingGroupResource = new BelongingGroupResource();
        belongingGroupResource.id = group.id;
        belongingGroupResource.description = group.description;
    
        resource.belonging_groups.push(belongingGroupResource);
     }
    } else {
      resource.belonging_groups = [];

    }

    return resource;
  }

  toJson(model: AreaTimeModel): any  {

    const resource = new AreaTimeSheetResource();
    if (model) {
      
      resource.belonging_groups = new Array<BelongingGroupResource>();
      for (const group of model.belonging_groups) {
         resource.belonging_groups.push(this.belongingSerializer.toJson(<BGroupModel>group));
      } 
    
    } 
    resource.cdc = model.cdc;
    resource.notes = model.notes;
    resource.typology = new UserTypologyResource();
    resource.typology.id = +model.typology; 

    


    return resource;
  }
}
