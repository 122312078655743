import { AreaResource, AreaType } from '../../area.resource';
import { BelongingGroupResource } from './belonging_groups.resource';
import { Resource } from 'src/app/core/common/http/resource.model';

export class AreaTimeSheetResource extends AreaResource {
  public static readonly TYPE = AreaType.timesheet;

  typology: UserTypologyResource;
  belonging_groups: BelongingGroupResource[];
  cdc: string;
  notes: string;

   constructor() {
    super(AreaTimeSheetResource.TYPE);

}}


export class UserTypologyResource extends Resource {
  id: number;
  description: string;

  constructor() {
    super();

  }
}



