import { AreaModel } from "../../area.model";


export class AreaMappatureModel extends AreaModel  {

    public roles: [];
    public notes: string; 


    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
