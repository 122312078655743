import { Injectable } from '@angular/core';
import { Serializer } from 'src/app/core/common/http/serializer';
import { CommentResource, CommentInfoResource } from './comment.model';

@Injectable({
  providedIn: 'root',
})
export class CommentSerializer implements Serializer {

  fromJson(json: any): CommentInfoResource {
    const resourceInfo = new CommentInfoResource();
    resourceInfo.comments = new Array<CommentResource>();
    for (const comment of json.comments) {
      const resource = new CommentResource();
      resource.id = json.id;
      resource.create_time = json.create_time;
      resource.message = json.message;
      resource.process_instance_id = json.process_instance_id;
      resource.task_id = json.task_id;
      resource.type = json.type;
      resource.user_id = json.user_id;
      resourceInfo.comments.push(resource);
      console.log(comment);
    }
    return resourceInfo;
  }

  toJson(model: any): any  {
    return null;
  }
}
