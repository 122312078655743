
import { AreaResource, AreaType } from '../../area.resource';

export class AreaAccountGoogleResource extends AreaResource {
   public static readonly TYPE =  AreaType.account_google;

   phone: string;
   notes: string;

   constructor() {
    super(AreaAccountGoogleResource.TYPE);
   }
}

