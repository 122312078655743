import { ProcessModel } from './process.model';
import { Injectable } from '@angular/core';
import { RequestSerializer } from 'src/app/richieste/resource/request.serializer';
import { TaskSerializer } from './task.serializer';
import { ApprovalResource, ApprovalInfoResource, HistoricApprovalResource } from './approval.resource';
import { Serializer } from 'src/app/core/common/http/serializer';

@Injectable({
  providedIn: 'root',
})
export class ApprovalSerializer implements Serializer {

  requestSerializer: RequestSerializer;
  taskSerializer: TaskSerializer;
  constructor() {
    this.requestSerializer = new RequestSerializer();
    this.taskSerializer = new TaskSerializer();

  }
  fromJson(json: any): ApprovalInfoResource {
    let resource: ApprovalInfoResource;

    const type = json.type;
    if (type === HistoricApprovalResource.TYPE) {
      resource = new HistoricApprovalResource();
    }  else {
      resource = new ApprovalResource();
    }

    resource.process_id = json.process_id;
    resource.status = json.status;
    resource.request = this.requestSerializer.fromJsonRequest(json.request);
    resource.task = this.taskSerializer.fromJson(json.task);

    return resource;
  }

  toJson(model: ProcessModel): any  {
    const resource = new ApprovalResource();
    return resource;
  }
}
