import { Serializer } from 'src/app/core/common/http/serializer';
import { RequestResource,  RequestSummaryResource, RequestDetailResource, RequestInfoResource } from './request.resource';
import { RequestModel } from '../model/request.model';
import { AreaSerializer } from '../area/area.serializer';
import { Injectable } from '@angular/core';
import { BeneficiarySerializer } from '../applicant-data/resource/beneficiary.serializer';
import { BeneficiaryResource } from '../applicant-data/resource/beneficiary.resource';
import { SubmitterSerializer } from './submitter.serializer';
import { RequestCreateResource } from './request-create.resource';
import { AssunzioniRichiestaSerializer } from 'src/app/assunzioni/resource/assunzioni.serializer';


@Injectable({
  providedIn: 'root',
})
export class RequestSerializer implements Serializer {

  beneficiarySerializer: BeneficiarySerializer;
  areaSerializer: AreaSerializer;
  submitterSerializer: SubmitterSerializer;
  assunzioniRichiestaSerializer: AssunzioniRichiestaSerializer;

  constructor() {
    this.beneficiarySerializer = new BeneficiarySerializer();
    this.areaSerializer = new AreaSerializer();
    this.submitterSerializer = new SubmitterSerializer();
    this.assunzioniRichiestaSerializer = new AssunzioniRichiestaSerializer();
  }

  fromJson(json: any): RequestInfoResource {
    let res = null;
    if(json!=null){
      const resourceInfo = new RequestInfoResource();
      let data;
      if (json.request) {
        data = json.request;
      } else {
        data = json;
      }
      resourceInfo.request = this.fromJsonRequest(data);
      res = resourceInfo;
    }
    return res;
  }

  fromJsonRequest(json: any): RequestResource {
    let resource: RequestResource;
    if (json.type) {
      if (json.type === RequestDetailResource.TYPE) {
        resource = new RequestDetailResource();
      } else {
        resource = new RequestSummaryResource();
      }
    } else {
      resource = new RequestSummaryResource();
    }
    if (json.beneficiary == undefined) {
      json = json[0];
    }
    resource.beneficiary = new Array<BeneficiaryResource>();
    if ( typeof json.beneficiary === 'object') {
      resource.beneficiary = json.beneficiary
    } else {
      for (const beneficiaryResource of json.beneficiary) {
        resource.beneficiary.push(this.beneficiarySerializer.fromJson(beneficiaryResource));
      }
    }
    resource.id = json.id;
    if (json.submitter) {
      resource.submitter = this.submitterSerializer.fromJson(json.submitter);
    } else {
      resource.submitter_id = json.submitter_id;

    }
    if (json.area) {
      resource.area = this.areaSerializer.fromJson(json.area);
    }
    if (json.engagement_id) {
      resource.spec_raas = this.assunzioniRichiestaSerializer.fromJsonRequest(json);
    }
    resource.process_id = json.process_id;

    resource.created_at = json.created_at;
    resource.modify_at = json.modify_at;
    resource.status = json.status;
    resource.ticket_id = json.ticket_id

    return resource;
  }

  toJson(model: RequestModel): any  {
    const resource = new RequestCreateResource();
    const requestResource = new RequestDetailResource();

    requestResource.area = this.areaSerializer.toJson(model.area);
    requestResource.beneficiary = new Array<BeneficiaryResource>();
    for (const beneficiaryModel of model.beneficiary) {
      requestResource.beneficiary.push(this.beneficiarySerializer.toJson(beneficiaryModel));
    }
    requestResource.submitter_id = model.submitter_id;

    resource.request = requestResource;
      return resource;
  }
}
