import { Serializer } from 'src/app/core/common/http/serializer';
import { FunctionModel } from '../model/functions.model';
import { FunctionsResource } from './functions.resource';

export class FunctionsSerializer implements Serializer {

  fromJson(json: any): FunctionModel {
    
    const model = new FunctionModel();
    model.id = json.id;
    model.description = json.description;

    return model;
  }

  toJson(model: FunctionModel): any  {
    
    
    const functionsResource = new FunctionsResource();
    const arr = model.id;
    const arr2 = model.description;

    functionsResource.id = arr;
    functionsResource.description = arr2;
 

    return functionsResource;
  }

}
