import { Resource } from 'src/app/core/common/http/resource.model';


export class UserResource extends Resource {
  id: number;
  last_name: string;
  first_name: string;
  email: string;
  image_url: string;
  display_name: string;
  cdc: string;
  matricola : string;
  typology: string;
  society?: any;
  type?: string;

 constructor() {
   super();
 }

}
