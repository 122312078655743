import { AreaModel } from "../../area.model";


export class AreaProlabQModel extends AreaModel  {

    public equivalent_profile_user: string; 
    public notes: string; 
    public type_profile : any;

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
