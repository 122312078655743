import { AreaModel } from "../../area.model";


export class AreaEcmModel extends AreaModel  {

    public assignment_groups: []; 
    public notes: string; 

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
