import { ProcessModel } from './process.model';
import { Injectable } from '@angular/core';
import { Serializer } from 'src/app/core/common/http/serializer';
import { TaskResource, TaskStatusResource, HistoricTaskResource, TaskInstanceResource } from './task.resource';

@Injectable({
  providedIn: 'root',
})
export class TaskSerializer implements Serializer {

  fromJson(json: any): TaskResource {
    let resource: TaskResource;

    const type = json.type;
    if (type === HistoricTaskResource.TYPE) {
      resource = new HistoricTaskResource();
    }  else {
      resource = new TaskInstanceResource();
    }

    resource.id = json.id;
    resource.task_def_key = json.task_def_key;
    resource.task_def_type = json.task_def_type;
    resource.assignee = json.assignee.display_name;
    resource.create_time = json.create_time;
    resource.end_time = json.end_time;
    resource.candidateGroup = json.candidateGroup;
    resource.delegationState = json.delegation_state;
    resource.status = TaskStatusResource[json.status.toUpperCase( )];

    return resource;
  }

  toJson(model: ProcessModel): any  {
    return null;
  }
}
