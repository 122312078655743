import { Serializer } from 'src/app/core/common/http/serializer';
import { AreaMappatureResource } from './area-mappature.resource';
import { RolesResource } from './roles.resource';
import { RolesModel } from '../model/roles.model';
import { AreaMappatureModel } from '../model/area-mappature.model';
import { RolesSerializer } from './roles.serializer';


export class AreaMappatureSerializer implements Serializer {

    rolesSerializer: RolesSerializer;

   constructor() {
    this.rolesSerializer = new RolesSerializer();
   }

  fromJson(json: any): AreaMappatureResource {

    const resource = new AreaMappatureResource();

    resource.notes = json.notes;

    if (json.roles) {
      resource.roles = new Array<RolesResource>();
      for (const role of json.roles) {
        const rolesResource = new RolesResource();
        rolesResource.id = role.id;
        rolesResource.description = role.description;
    
        resource.roles.push(rolesResource);
     }
    } else {
      resource.roles = [];

    }

    return resource;
  }

  toJson(model: AreaMappatureModel): any  {
    

    const resource = new AreaMappatureResource();
    if (model.roles && model.roles.length > 0 ) {
      resource.roles = new Array<RolesResource>();
      for (const group of model.roles) {
         resource.roles.push(this.rolesSerializer.toJson(<RolesModel>group));
      } 
    } 
    resource.notes = model.notes;
   


    return resource;
  }
}
