import { Serializer } from 'src/app/core/common/http/serializer';
import { PageInfo } from './pageinfo.resource';

export class PageInfoSerializer implements Serializer {

  fromJson(json: any): PageInfo {
    const resource = new PageInfo();
    resource.size = json.size;
    resource.totalElements = json.totalElements;
    resource.totalPages = json.totalPages;
    resource.number = json.number;
    return resource;
  }

  toJson(resource: PageInfo): any  {
    return {};
  }
}
