import { AreaModel } from "../../area.model";


export class AreaEsightModel extends AreaModel  {

    public profile: any[] = []; 
    public functions: [];
    public commesse: string;
    public notes: string; 


    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }

}
