import { AreaModel } from "../../area.model";


export class AreaSupervisionModel extends AreaModel  {

    public system: any[] = []; 
    public notes: string; 

    // Definisco un construttore con valori di default vuoti
    constructor() {
      super();
    }
}
