import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/home/dashboard',
    title: 'Home page',
    icon: 'icon-Home',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Applicativi',
    icon: 'ti-clipboard',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/applicativi/nuova-richiesta',
        title: 'Nuova richiesta',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/applicativi/mie-richieste',
        title: 'Mie richieste',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      },  
      {
        path: '/applicativi/approvazioni',
        title: 'Approvazioni',
        icon: 'icon-Check',
        class: '',
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Assunzioni',
    icon: 'ti-id-badge',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/assunzioni/nuova-richiesta',
        title: 'Nuova richiesta',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/assunzioni/mie-richieste',
        title: 'Mie richieste',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/assunzioni/approvazioni',
        title: 'Approvazioni',
        icon: 'icon-Check',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/assunzioni/gestione',
        title: 'Gestione',
        icon: 'icon-Check',
        class: '',
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Trasferte',
    icon: 'ti-car',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/trasferte/nuova-richiesta',
        title: 'Nuova richiesta',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/trasferte/mie-richieste',
        title: 'Mie richieste',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/trasferte/approvazioni',
        title: 'Approvazioni',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Dati',
    icon: 'mdi mdi-cube-outline',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/data/nuova-richiesta',
        title: 'Nuova richiesta',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/data/mie-richieste',
        title: 'Mie richieste',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/data/approvazioni',
        title: 'Approvazioni',
        icon: '',
        class: '',
        extralink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Amministrazione',
    icon: 'ti-settings',
    class: 'has-arrow',
    adminOrSuperAdmin : true,
    extralink: false,
    submenu: [
      {
        path: '/amministrazione/tutte-le-richieste',
        title: 'Richieste Applicativi',
        icon: '',
        class: '',
        isAppAdmin: true,
        extralink: false,
        submenu: [],
      },
      {
        path: '/amministrazione/assunzioni/tutte-le-richieste',
        title: 'Richieste Assunzioni',
        icon: '',
        class: '',
        isEngAdmin: true,
        extralink: false,
        submenu: [],
      },
      {
        path: '/amministrazione/trasferte/tutte-le-trasferte',
        title: 'Richieste Trasferte',
        icon: '',
        class: '',
        isBusinessTripAdmin: true,
        extralink: false,
        submenu: [],
      }
    ],
  }  
];
