import { AreaResource, AreaType } from '../../area.resource';
import { RolesResource } from './roles.resource';

export class AreaMappatureResource extends AreaResource {
  public static readonly TYPE = AreaType.mappature;

  roles: RolesResource[];
  notes: string;

   constructor() {
    super(AreaMappatureResource.TYPE);

 }
}


