import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ResourceService } from 'src/app/core/common/http/resource-service.service';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { ProcessSerializer } from '../resource/process.serializer';
import { ProcessInfoResource } from '../resource/process.resource';
import { ApprovalActionResource } from '../approvazioni-action/resource/approval-action.resource';
import { Observable } from 'rxjs';
import { CommentInfoResource } from '../resource/comment.model';
import { CommentSerializer } from '../resource/comment.serializer';

@Injectable({
  providedIn: 'root',
})
export class ProcessRestService extends ResourceService<ProcessInfoResource> {
  filterForm: FormGroup;
  commentSerializer: CommentSerializer;

  constructor(
    httpClient: HttpClient, 
    processSerializer: ProcessSerializer, 
    commentSerializer: CommentSerializer) {
    super(
      httpClient,
      environment.backend.baseUrl,
      'process',
      processSerializer
    );
    this.commentSerializer = commentSerializer;

  }

     /** Ricerca il processo nella history
   * @param req
   */
  public history(process_id: string): Observable<ProcessInfoResource> {
    const requestURL = `${this.baseUrl}/${this.uri}/history/${process_id}`;
    return this.httpClient.get(requestURL, { observe: 'response' })
    .map((res: HttpResponse<ProcessInfoResource>) => this.convertData(res.body));
  }

  public findAs(process_id: string): Observable<ProcessInfoResource> {
    const requestURL = `${this.baseUrl}/${this.uri}/${process_id}`;
    return this.httpClient.get(requestURL, { observe: 'response' })
    .map((res: HttpResponse<ProcessInfoResource>) => this.convertData(res.body));

  }

   /** Action su di un task
   * @param req
   */
  public action(process_id: string, task_id: string, body: ApprovalActionResource): Observable<HttpResponse<Object>> {
    const requestURL = `${this.baseUrl}/${this.uri}/${process_id}/task/${task_id}/action`;
    return this.httpClient.post(requestURL, body, { observe: 'response' });
  }

  /**
   * 
   * @param process_id Action engagement
   * @param engagement_id 
   * @param body 
   * @returns 
   */
  public hiringAction(process_id: string, engagement_id: string, task_id: string, body: ApprovalActionResource): Observable<HttpResponse<Object>> {
    const requestURL = `${this.baseUrl}/engagement/${engagement_id}/process/${process_id}/task/${task_id}/action`;
    return this.httpClient.post(requestURL, body, { observe: 'response' });
  }
  

     /** Action su di un task
   * @param req
   */
  public listComments(process_id: string, task_id): Observable<HttpResponse<CommentInfoResource>> {
    const requestURL = `${this.baseUrl}/${this.uri}/${process_id}/task/${task_id}/comments`;
    return this.httpClient.get(requestURL, { observe: 'response' })
    .map((res: HttpResponse<CommentInfoResource>) =>  {
       const body = res.body;
       return res.clone( { body });
    });
  }

  public listCommentsEngagment(engagement_id: string, process_id: string, task_id): Observable<HttpResponse<CommentInfoResource>> {
    const requestURL = `${this.baseUrl}/engagement/${engagement_id}/process/${process_id}/task/${task_id}/comments`;
    return this.httpClient.get(requestURL, { observe: 'response' })
    .map((res: HttpResponse<CommentInfoResource>) =>  {
       const body = res.body;
       return res.clone( { body });
    });
  }

}
