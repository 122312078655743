import { Component, ViewEncapsulation, Input } from '@angular/core';
import { BeneficiaryResource } from 'src/app/richieste/applicant-data/resource/beneficiary.resource';
import { ApprovalService } from '../../../approvazioni/service/approval.service';

@Component({
  selector: 'app-beneficiary-detail',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './beneficiary-detail.html',
  styleUrls : ['./beneficiary-detail.css']
})
export class BeneficiaryDetailComponent {
  @Input() public beneficiary: BeneficiaryResource;
  constructor(private _approvalService: ApprovalService ) {}
  renderUser(): any {
    return this._approvalService.renderUser(this.beneficiary);
  }
}

