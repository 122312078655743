
import { Resource } from 'src/app/core/common/http/resource.model';
import { AreaResource, AreaType } from '../../area.resource';

export class AreajaggaerResource extends AreaResource {
   public static readonly TYPE =  AreaType.jaggaer;

   role: any;
   notes: string;
  
   constructor() {
    super(AreajaggaerResource.TYPE);
   }
}


export class UserOfAjaggaerTypeResource extends Resource {
   id: string;
   value: string;
  
   constructor() {
     super();
 
   }
 }
 


