import { Component, OnInit, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionType } from './resource/action.resource';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdditionalFields } from './resource/additionalFields.resource';
import { ApprovalResource } from '../resource/approval.resource';


@Component({
  templateUrl: './action.component.html'
})
export class ActionConfirmComponent {
  @Input() public actionBody: string;
  constructor(
    public activeModal: NgbActiveModal
  ) { }
  confirm() {
    this.activeModal.close({ confirm: true });
  }
  cancel() {
    this.activeModal.close('Close click');
  }
}

@Component({
  templateUrl: 'action-modal-component.component.html'
})
export class ActionModalComponent implements OnInit {
  comment: string;

  approval_power: string = "";
  approvalPowerVisible: boolean = false;
  selectedUser;

  @Input() public actionType: ActionType;
  @Input() public taskId: string;

  @Input() public rowApproval: ApprovalResource;
  @Input() public addFields: AdditionalFields;
  @Input() public delega: any;
  @Input() public isTrasferta: boolean;

  commentForm: FormGroup;
  commentTitleAddition: string = '(Opzionale)';
  actionModalTitle: string = "";
  isHRBP: boolean = false;
  isOpenSelectionUser: boolean = false;
  isPAYROLL: boolean = false;
  isDelega: boolean = false;
  dateFrom: any;
  dateTo: any;
  isUpdate: boolean = false;

  constructor(
    private currencyPipe: CurrencyPipe,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    let form = {};
    let actionTitle;
    let commentValidations = [];

    this.switcher(form);

    this.actionModalTitle = actionTitle;

    if (this.actionType === ActionType.DENY || this.actionType === ActionType.ACTION_BUTTON_ID_REJECTED || this.actionType === ActionType.ACTION_BUTTON_ID_REJECTED_PAYROLL) {
      commentValidations.push(Validators.required);
      this.commentTitleAddition = '*';
    }

    if(this.actionType === ActionType.CANCEL) {
      this.actionModalTitle = 'Annulla';
    } else if(this.actionType === ActionType.DELEGA) {
      this.actionModalTitle = 'Delega';
    }
    
    form['comment'] = new FormControl(null, commentValidations);
    //Controllo se esiste rowApproval arrivo dalla tabella
    //controllo se il task è assegnato ad un utenza HR
    //Controllo e inserire o no approval_power
    this.controllApprovalPower(form);
    this.commentForm = this.fb.group(form);
  }

  controllApprovalPower(form: {}) {
    if (this.actionType == ActionType.APPROVE) {
      if (this.rowApproval && this.rowApproval.task && this.rowApproval.task.task_def_key == "checkHr") {
        this.addFields = new AdditionalFields();
        this.addFields.fields = {
          approval_power: ""
        }
      }
      if (this.addFields && this.addFields.fields && this.addFields.fields.hasOwnProperty("approval_power")) {
        form['approval_power'] = new FormControl(null, [
          Validators.required,
          Validators.pattern("^[0-9][0-9.]+$"),
          this.approvalPowerValidator
        ]);
        this.approvalPowerVisible = true;
      }
    } else {
      this.addFields = null;
    }
  }

  switcher(form: any) {
    let actionTitle;
    switch (this.actionType) {
      case ActionType.APPROVE: {
        actionTitle = 'Approvazione';
        break;
      }
      case ActionType.DENY: {
        actionTitle = 'Rifiuto';
        break;
      }
      case ActionType.TAKING_CHARGE_HRBP: {
        actionTitle = 'Riassegna Censimento new entry';
        break;
      }
      case ActionType.ACTION_BUTTON_ID_REJECTED: {
        actionTitle = 'Riassegna Censimento new entry';
        this.isHRBP = true;
        break;
      }
      case ActionType.ACTION_BUTTON_ID_REJECTED_PAYROLL: {
        actionTitle = 'Riassegna Payroll';
        this.isPAYROLL = true;
        break;
      }
      case ActionType.DELEGA: {
        actionTitle = 'Delega';
        this.isDelega = true;
        form['dateFrom'] = new FormControl(null, [
          Validators.required]);
        form['dateTo'] = new FormControl(null, [
          Validators.required]);
        if (this.delega && this.delega.id) {
          this.isUpdate = true;
          this.dateFrom = new Date(this.delega.start_date).toISOString().substring(0, 10);
          this.dateTo = new Date(this.delega.end_date).toISOString().substring(0, 10);
          this.saveUser(this.delega.delegated_email);
        }
        break;
      }
      case ActionType.CENSUS_CLOSE: {
        actionTitle = 'Chiusura ricerca';
        break;
      }
      case ActionType.CANCEL: {
        actionTitle = 'Annulla';
        break;
      }
    }
    return actionTitle;
  }

  get form() { return this.commentForm; }
  get formControls() { return this.commentForm.controls; }

  approvalPowerValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (c.pristine) {
      return null;
    }
    if (c.value) {
      let val = c.value.replaceAll(".", "");
      if (val.length <= 10) {
        val = parseInt(val);
        if (val > 2147483647) {
          return { 'approvalPowerValidator': false };
        }
      }
    }
    return null;
  }

  saveUser(user) {
    this.selectedUser = user;
    this.isOpenSelectionUser = false;
  }

  action(evt: any) {
    if ((this.commentForm.valid && !this.isHRBP && !this.isPAYROLL) || (this.commentForm.valid && this.isHRBP && this.selectedUser) || (this.commentForm.valid && this.isPAYROLL && this.selectedUser) ||
      (this.isDelega && this.dateFrom && this.dateTo && this.selectedUser)) {
      if(evt) {
        evt.preventDefault();
      }
      let tmpFields = this.getFields();
      if (this.isDelega) return this.delegaReturn(this.selectedUser);
      if (this.selectedUser) this.approvalActionWithCommentAndUser(this.actionType, this.comment, tmpFields, this.selectedUser)
      this.approvalAction(this.actionType, this.comment, tmpFields);
    } else {
      this.toastr.error('Errore nell\'invio della richiesta. Controlla che tutti i campi obbligatori siano stati compilati correttamente.', 'Invio richiesta fallito', {
        timeOut: 5000
      });
    }
  }

  close() {
    this.activeModal.dismiss('Cross click');
  }

  delegaReturn(seletedUser: any) {
    this.activeModal.close({ action: "create", email: seletedUser, dateFrom: this.dateFrom, dateTo: this.dateTo });
  }

  cancel() {
    this.activeModal.close({ action: "delete" });
  }

  update() {
    this.activeModal.close({ action: "update", email: this.selectedUser, dateFrom: this.dateFrom, dateTo: this.dateTo });
  }

  approvalAction(actionType: ActionType, comment?: string, addFields?: AdditionalFields) {
    // Restituisco la response
    this.activeModal.close({ actionType: actionType, comment: comment, addFields: addFields });
  }

  approvalActionWithCommentAndUser(actionType: ActionType, comment?: string, addFields?: AdditionalFields, seletedUser?: any) {
    // Restituisco la response
    this.activeModal.close({ actionType: actionType, comment: comment, addFields: addFields, selectedUser: seletedUser });
  }

  getFields() {
    let tmpAddFields = null;
    if (this.addFields) {
      if (this.addFields.fields) {
        tmpAddFields = this.addFields;
        if (this.addFields.fields.hasOwnProperty("approval_power")) {
          let curr: any = this.approval_power;
          curr = curr.replaceAll(".", " ").replaceAll(",", ".").replaceAll(" ", "").replaceAll("€", "");
          tmpAddFields.fields["approval_power"] = curr;
        }
      }
    }
    return tmpAddFields;
  }


  updateApprovalPowerField(value): void {
    if (value != null) {
      if (this.approval_power != null) {
        let curr: any = this.approval_power;
        curr = curr.replaceAll(".", "").replaceAll(",", ".").replaceAll(" ", "").replaceAll("€", "");
        if (curr.match("[0-9.]+$") != null) {
          this.approval_power = this.currencyPipe.transform(curr, '€');
          this.approval_power = this.approval_power.substring(0, this.approval_power.length - 5);
          value.target.value = this.approval_power;
        }
      }
    }
  }


  formatEUR(number: number) {
    let curr = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(number);
    return curr.substring(0, curr.length - 2);
  }

  openSelectionUser() {
    this.isOpenSelectionUser = true;
  }

  selectDate(i: number) {
    if (i == 1) {
      if (new Date(this.dateFrom) < new Date()) {
        this.dateFrom = new Date().toISOString().substring(0, 10);
      }
      if (this.dateTo && new Date(this.dateTo) < new Date(this.dateFrom)) {
        this.dateTo = new Date(this.dateFrom).toISOString().substring(0, 10);
      }
    } else if (i == 2) {
      if (new Date(this.dateTo) < new Date()) {
        this.dateTo = new Date().toISOString().substring(0, 10);
      }
      if (this.dateFrom && new Date(this.dateTo) < new Date(this.dateFrom)) {
        this.dateTo = new Date(this.dateFrom).toISOString().substring(0, 10);
      }
    }
  }

}












