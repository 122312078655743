import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sino'
})
export class SinoPipe implements PipeTransform {
  transform(value: any): any {
    let res = "";
    if(value != null && typeof value != typeof undefined){
      if(value == true){res = "Si"}
      else if(value == false){res="No";}
      else{res=value;}
    }
    return res;
  }
}
