import { AreaResource, AreaType } from './area.resource';

export class AreaSummaryResource extends AreaResource {
  public static readonly TYPE = AreaType.summary;

  area_type: string;

  constructor() {
    super(AreaSummaryResource.TYPE);
  }
}
