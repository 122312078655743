
import { AreaResource, AreaType } from '../../area.resource';
export class AreaEtimeResource extends AreaResource {
   public static readonly TYPE =  AreaType.etime;

   cdc: string;
   notes: string;
   

   constructor() {
    super(AreaEtimeResource.TYPE);
   }
}

