import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RipuDatePipeFormat } from './pipe/ripu.datepipe';
import { SinoPipe } from './pipe/sino.pipe';
import { GoogleInputPlacesDirective } from './directives/googleinputplaces.directive';


@NgModule({
 imports:      [
   CommonModule  // CommonModule because the module's component needs common directives.
 ],
 declarations: [
  RipuDatePipeFormat,  // Importo la pipe in formato ripu
  SinoPipe,
  GoogleInputPlacesDirective
  ],
  entryComponents: [
  ],
  providers: [RipuDatePipeFormat],
 exports:    [ 
  CommonModule , 
  RipuDatePipeFormat,
  SinoPipe,
  GoogleInputPlacesDirective ]
})
export class SharedModule { }
